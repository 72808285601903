export const useColumnCount = (
  configConstant: { [key: string]: { [key: string]: number } },
  devices: string[],
) => {
  const isXsDisplay = inject<Ref<boolean>>('isXsDisplay');
  const isMobileMode = inject<Ref<boolean>>('isMobileMode');

  return computed(() => {
    const config = configConstant;

    if (!config) {
      throw new Error('Config constant is not provided');
    }

    if (isXsDisplay?.value && devices.includes('isXsDisplay')) {
      return config.xs.columns;
    } else if (isMobileMode?.value && devices.includes('isMobileMode')) {
      return config.mobile.columns;
    } else {
      return config.desktop.columns;
    }
  });
};
